import { effect, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { UserEntity } from "../../api/models/fraud.models";
import { FraudService } from "../../api/services/fraud.service";
import { Observable, of, take, tap } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserControllerService {
  public readonly user: WritableSignal<UserEntity | undefined> = signal<UserEntity | undefined>(undefined);
  public readonly telegramUserId: WritableSignal<string | undefined> = signal<string | undefined>(undefined);
  private readonly _fraudService: FraudService = inject(FraudService);

  constructor() {
    effect((): void => {
      const telegramUserId: string | undefined = this.telegramUserId();
      if (telegramUserId) {
        localStorage.setItem('telegramUserId', telegramUserId);
      }
    });
  }

  getUser(): Observable<UserEntity | undefined> {
    const telegramUserId: string | undefined = this.telegramUserId();
    return telegramUserId ? this._fraudService.getUserByTelegramChatId(telegramUserId).pipe(tap((user: UserEntity): void => {
      this.user.set(user)
    })) : of(undefined);
  }

  refresh(): Observable<UserEntity | undefined> {
    return this.getUser();
  }
}
